<template>
  <div class="agreement">
    <h2>个人信息处理知情同意书</h2>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本人知晓并同意&nbsp;&nbsp;&nbsp;成都亿贝特电子科技有限公司&nbsp;（以下简称“&nbsp;&nbsp;&nbsp;亿贝特&nbsp;”）收集本人所提交的信息向以下合作方全部或部分共享或提供给其他个人信息处理者（以下简称“接收方”），直至实现以下合法目的和法律、法规允许的期限届满。
    </p>
    <div>
      <el-table
        :data="tableData"
        :border="true"
        :show-header="false"
        style="width: 70%; margin: auto"
      >
        <el-table-column prop="title" width="180"> </el-table-column>
        <el-table-column prop="content"> </el-table-column>
      </el-table>
    </div>
    <p>
      本人已知悉并同意《个人信息处理知情同意书》。<br />
      如需了解详细的个人信息处理规则，请查阅
      <router-link to="/privacyAgreement">
        《隐私保护指引》
      </router-link>
      。
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          title: "接收方名称",
          content:
            "【  成都亿贝特电子科技有限公司 】及其关联方受产品活动规则约束，前述接收方以活动页面弹层、弹窗或者短信及其他合法的方式告知本人且最终为本人提供产品服务的服务商为准。",
        },
        {
          title: "处理目的",
          content:
            "向本人提供“   海科融通x易刷4G电签  ”产品服务、签订或履行相关“   海科融通x易刷4G电签  ”产品合同、提供相关售后服务、提供相关增值服务、提供其他泛金融产品和服务、市场调研或数据处理、履行法定职责或法定义务等合法目的",
        },
        {
          title: "处理方式",
          content: "收集、存储、使用、传输",
        },
        {
          title: "是否涉及敏感个人信息",
          content: "否",
        },
      ],
    };
  },
};
</script>
