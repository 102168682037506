<template>
  <div class="container">
    <router-view></router-view>
    <div class="package-flow">
      <div class="flow-explain flex">
        <div class="flow-left flex">
          <el-image
            class="flex-left-img"
            :src="require('../assets//check.png')"
          ></el-image>
          <div class="fe-title">恭喜您获得POS机幸运礼包</div>
          <!-- <div class="fe-subtitle">POS机已打包，等待确认地址</div> -->
        </div>
        <!-- <el-image
          class="flow-right"
          :src="require('../assets/package_icon.png')"
        ></el-image> -->
      </div>
      <!-- <div class="steps flex">
        <div class="step flex center2">
          <el-image :src="require('../assets/step1.png')"></el-image>
          <span>已审核</span>
        </div>
        <div class="line"></div>
        <div class="step flex center2">
          <el-image :src="require('../assets/step2.png')"></el-image>
          <span>已打包</span>
        </div>
        <div class="line"></div>
        <div class="step flex last-step">
          <el-image :src="require('../assets/step4.png')"></el-image>
          <span>待确认</span>
        </div>
        <div class="line-black"></div>
        <div class="step flex last-step">
          <el-image :src="require('../assets/step3.png')"></el-image>
          <span>已收货</span>
        </div>
      </div> -->
    </div>
    <!-- <router-link style="text-decoration: none; color: black" to="/addressInfo"> -->
      <div class="improve-info flex" @click="showAddress()">
        <div class="improve-info-left flex">
          <el-image
            style="max-width: 1.8rem"
            :src="require('../assets/address.png')"
          ></el-image>
          &nbsp;
          <div>收件地址待填写</div>
        </div>
        <div class="improve-info-right">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    <!-- </router-link> -->

    <!-- <el-image :src="require('../assets/1top.jpg')"></el-image> -->
    <!-- <div class="time-container flex">
      <i class="el-icon-alarm-clock"></i>
      <div class="time flex">
        <div>订单将失效，请在</div>
        <div class="time-txt">0</div>
        <div class="time-txt">0</div>
        <div class="time-colon">:</div>
        <div class="time-txt">{{ remainTime.substring(0, 1) }}</div>
        <div class="time-txt">{{ remainTime.substring(1, 2) }}</div>
        <div class="time-colon">:</div>
        <div class="time-txt">{{ remainTime.substring(3, 4) }}</div>
        <div class="time-txt">{{ remainTime.substring(4, 5) }}</div>
        <div>内完善</div>
      </div>
    </div> -->
    <div class="pos-container">
      <el-image :src="require('../assets/container_image.png')"></el-image>
      <div class="container-top flex">
        <!-- <div class="container-top-left flex">
          <el-image
            class="union-pay"
            fit="contain"
            :src="require('../assets/icon.png')"
          ></el-image>
          <div class="unipay-text">银联认证体验中心</div>
        </div>
        <div class="color-red">系统确认完成</div> -->
        <div class="top-item flex">
          <div class="top-item-one flex">升级版低费率还款神器</div>
          <div class="top-item-two flex">
            <div>银联认证智能POS刷卡机</div>
            <el-image
              class="top-item-two-image"
              :src="require('../assets/help.png')"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="container-center flex">
        <div class="container-center-left">
          <el-image
            class="pos"
            fit="contain"
            :src="require('../assets/pos.png')"
          ></el-image>
        </div>
        <div class="container-center-right flex">
          <!-- <div class="center1 flex">
            <div class="pos-name color-black">提额智能收款POS机</div>
            <div class="pos-num">x1</div>
          </div> -->
          <!-- <div class="center2 flex">
            <div class="center2-text">蓝色</div>
            <div class="center2-text">费率低至0.38%</div>
            <div class="center2-text">免费领取</div>
          </div> -->
          <!-- <div class="center3 flex"> -->
          <!-- <div>
            <li>
              <span class="color-gray">支持微信、支付宝、银联、云闪付等</span>
            </li>
          </div>
          <div>
            <li>
              <span class="color-gray">无需3元秒到费，秒审核，即领即发货</span>
            </li>
          </div>
          <div>
            <li>
              <span class="color-red">仅限信用卡用户领取</span>
            </li>
          </div> -->
          <!-- <div class="center3-bottom color-black">
            共计1件：<span class="color-red">礼品</span>
          </div> -->
          <!-- </div> -->
          <div class="center3-items flex">
            <div class="center3-item-no">1</div>
            <div class="center3-item-value">费率低至0.38%</div>
          </div>
          <div class="center3-items flex">
            <div class="center3-item-no">2</div>
            <div class="center3-item-value">可刷个人信用卡，极速到账</div>
          </div>
          <div class="center3-items flex">
            <div class="center3-item-no">3</div>
            <div class="center3-item-value">
              支持微信、支付宝、银联、云闪付等多种付款方式
            </div>
          </div>
          <div class="center3-items flex">
            <div class="center3-item-no">4</div>
            <div class="center3-item-value">信用卡用户免费申领</div>
          </div>
        </div>
      </div>
      <div class="container-bottom flex">
        <!-- <el-image
          class="icon-time"
          :src="require('../assets/time.png')"
        ></el-image>
        <div class="color-gray">预计时间：尽快送达</div> -->
        <div style="margin-bottom: 1rem;font-size: 1rem;">当前状态</div>
        <div class="container-bottom-step">
          <el-steps :active="0" align-center finish-status="success">
            <el-step title="填写地址"></el-step>
            <el-step title="安全发货"></el-step>
            <el-step title="极速到达"></el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <div v-if="showAddressInfo" class="address-container">
      <div class="address-info-page">
        <div class="close-btn" @click="showAddress()">
          <el-image class="close-icon" :src="require('../assets/close.png')">
          </el-image>
        </div>
        <Address></Address>
      </div>
    </div>
    <!-- <div class="apply-tips flex">申请成功后，可获得订单编号</div> -->
    <!-- <el-image class="flow" :src="require('../assets/flow.png')"></el-image> -->
    <div
      style="
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 300px;
        margin: 0 auto;
        padding-top: 20px;
      "
    >
      <el-link
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001975"
        style="display: inline-block; text-decoration: none"
        ><img src="../assets/gongan.png" style="float: left" />
        <p style="float: left; margin: 0px 0px 0px 5px; color: #939393">
          川公网安备 51010602001975号
        </p></el-link
      >
    </div>
    <div
      style="
        width: 300px;
        margin: 0 auto;
        padding-bottom: 20px;
        text-align: center;
      "
    >
      <el-link href="https://beian.miit.gov.cn" target="_blank">
        <p style="margin: 0px 0px 0px 5px; color: #939393">
          蜀ICP备2023007112号-2
        </p></el-link
      >
    </div>
    <div class="space"></div>
    <div class="bottom-btn flex" v-if="showBottomBtn">
      <div
        style="
          display: flex;
          align-items: end;
          flex: 3;
          justify-content: flex-end;
        "
      >
        <div style="color: gray; font-size: 0.9rem">共1件，</div>
        <div style="font-size: 1rem">合计：</div>
        <div style="font-size: 1.2rem; color: red">￥免费</div>
      </div>
      <el-button class="addr-btn" @click="showAddress()"> 填写地址 </el-button>
    </div>
  </div>
</template>

<script>
import Address from "./AddressInfo.vue";
export default {
  components: {
    Address,
  },
  data() {
    return {
      remainTime: "14:59",
      countdown: null, // 添加一个 countdown 变量来存储定时器的引用
      showAddressInfo: false,
      showBottomBtn:true
    };
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown(); // 在组件销毁前停止定时器
  },
  methods: {
    showAddress() {
      this.showAddressInfo = !this.showAddressInfo;
      this.showBottomBtn = !this.showBottomBtn;
    },
    startCountdown() {
      var countdownDuration = 15 * 60 * 1000;
      var startTime = new Date().getTime();
      var endTime = startTime + countdownDuration;

      this.countdown = setInterval(() => {
        // 存储定时器的引用到 countdown 变量中
        var now = new Date().getTime();
        var timeDifference = endTime - now;
        if (timeDifference <= 1) {
          this.stopCountdown(); // 停止定时器
          console.log("倒计时结束!");
          this.startCountdown();
          return;
        }
        var minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        minutes = this.padZero(minutes);
        seconds = this.padZero(seconds);

        this.remainTime = minutes + ":" + seconds;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdown); // 清除定时器
    },
    padZero(num) {
      return num.toString().padStart(2, "0");
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  background-color: whitesmoke;
}
.package-flow {
  height: auto;
  overflow: hidden;
  background-color: #1282ff;
  background-image: linear-gradient(
    129deg,
    #1282ff 0%,
    #1191db 11%,
    #6889fc 75%
  );
  // border-radius: 1rem 1rem 1rem 1rem;
  box-shadow: 3px 4px 4px #e7eefc;
}
.flow-explain {
  padding: 3vh 0vh 3vh 0vh;
  justify-content: center;
}
.improve-info {
  height: 5rem;
  margin: 2vh 1vh 2vh 1vh;
  padding: 0vh 2vh 0vh 2vh;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-size: 1.2rem;
}
.improve-info-left {
  padding-right: 2rem;
  align-items: center;
}
.improve-info-right {
  font-size: 1.5rem;
}
.flow {
  width: 95%;
}
.apply-tips {
  padding: 3% 0 3% 4%;
  // background-color: rgb(249,251,255);
  font-size: 16px;
}
.flow-left {
  align-items: center;
  line-height: 1.7rem;
}
.flex-left-img {
  width: 1.8rem;
  height: 1.8rem;
}
.flow-right {
  width: 4rem;
  height: 4rem;
}
// ----
.steps {
  padding: 2vh 1.5vh 2vh 1.5vh;
  background-color: white;
  justify-content: space-around;
  font-size: 0.8rem;
}
.step {
  flex: 2;
  max-width: 5vh;
  flex-direction: column;
  justify-content: space-around;
}
.line {
  flex: 1;
  margin: 0 0.5rem 0 0.5rem;
  position: relative;
}

.line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #1191db; /* 横线的样式 */
}
.line-black {
  flex: 1;
  margin: 0 0.5rem 0 0.5rem;
  position: relative;
}

.line-black::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid black; /* 横线的样式 */
}

.last-step {
  color: #b5b5b5;
}
.fe-title {
  color: white;
  font-size: 1.6rem;
}
.fe-subtitle {
  color: white;
  font-size: 1rem;
}
.time-container {
  width: 100%;
  height: 7vh;
  color: white;
  background-color: #3abbfd;
  background-image: linear-gradient(
    129deg,
    #3abbfd 0%,
    #42b2fd 11%,
    #6889fc 75%
  );
  justify-content: center;
  align-items: center;
}
.time {
  align-items: center;
  line-height: 3vh;
}
.time-txt {
  color: rgb(109, 158, 252);
  font-weight: 600;
  border-radius: 0.12rem;
  margin: 0 0.1rem 0 0.1rem;
  padding: 0 0.1rem 0 0.1rem;
  background-color: white;
}
.time-colon {
  font-weight: 600;
  margin: 0 0.1rem 0 0.1rem;
}
//  ---
.flex {
  display: flex;
}

.pos-container {
  background-color: white;
  margin-bottom: 20px;
  margin: 2vh 1vh 2vh 1vh;
  padding: 2vh 2vh 2vh 2vh;
}

.container-top {
  font-size: 13px;
  padding: 1rem 0 1rem 0;
  height: 48px;
  justify-content: center;
  align-content: center;
}

.container-top-left {
  align-items: center;
}
.top-item {
  justify-content: center;
  flex-direction: column;
  align-content: center;
  width: fit-content;
}
.top-item-one {
  font-size: 1.4rem;
  font-weight: 800;
}
.top-item-two {
  font-size: 1.0rem;
  line-height: 1.2rem;
}
.top-item-two-image {
  width: 1.2rem;
  height: 1.2rem;
}
.unipay-text {
  font-weight: 600;
  font-size: 18px;
}

.container-center {
  height: 210px;
}

.container-center-left {
  flex: 1;
}

.container-center-right {
  flex: 2;
  padding-right: 12px;
  margin-top: 4px;
  // color: rgb(245, 133, 18);
  font-size: 14px;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;
}

.center1 {
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.center2 {
  color: rgb(62, 152, 224);
}

.center2-text {
  border: solid 1px rgb(62, 152, 224);
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
}

.center3 {
  flex-direction: column;
  // flex-direction: column;
  align-items: baseline;
}

.center3-bottom {
  align-self: flex-end;
}
.center3-items {
  color: black;
  line-height: 1.3rem;
}
.center3-item-no {
  color: white;
  min-width: 1.3rem;
  min-height: 1.3rem;
  max-height: 1.3rem;
  border-radius: 20rem;
  font-size: 1rem;
  background: repeating-linear-gradient(
    to left,
    rgba(76, 183, 235),
    rgba(109, 140, 227)
  );
}
.center3-item-value {
  font-size: 0.88rem;
  margin-left: 1rem;
}
.container-bottom {
  padding: 10px;
  font-size: 0.9rem;
  color: black;
  flex-direction: column;
  text-align: left;
}
.container-bottom-step {
  color: gray;
}
.icon-time {
  width: 25px;
  height: 25px;
}

.pos-name {
  font-size: 18px;
  font-weight: 600;
}

.pos-num {
  color: rgb(101, 101, 101);
}

.union-pay {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.pos {
  height: 80%;
  width: 95%;
}
.addr-btn {
  margin: 0 2rem 0 1.5rem;
  flex: 2;
  background-color: rgb(47, 124, 252) !important;
  border-radius: 30px !important;
  width: 100%;
  height: 70%;
  font-size: 21px !important;
  color: aliceblue !important;
}

.color-red {
  color: red;
}

.color-black {
  color: black;
}

.color-gray {
  color: rgba(65, 65, 65, 0.795);
}
.space {
  height: 4rem;
}
.bottom-btn {
  justify-content: end;
  align-items: center;
  position: fixed;
  background-color: white;
  bottom: 0px;
  width: 100%;
  max-width: 600px;
  height: 5rem;
  padding-right: 2rem;
  z-index: 10;
}
.address-container {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 600px;
  top: 0rem;
  background-color: #32323285;
  z-index: 11;
}
.address-info-page {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 600px;
  top: 6rem;
  bottom: 0rem;
  z-index: 10;
  background-color: white;
}
.close-btn {
  display: flex;
  justify-content: flex-end;
  background-color: white;
}
.close-icon {
  width: 3rem;
  height: 3rem;
}
</style>
