<template>
  <div class="container">
    <div class="login-box">
      <h3>系统登录</h3>
      <div>
        <el-form
          ref="formLabelAlign"
          :label-position="labelPosition"
          :model="formLabelAlign"
          :rules="loginRules"
        >
          <el-form-item prop="name">
            <el-input
              class="login-input"
              placeholder="Username"
              v-model="formLabelAlign.name"
              ><i slot="prefix" class="el-icon-user"></i
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="login-input"
              placeholder="Password"
              v-model="formLabelAlign.password"
              type="password"
              ><i slot="prefix" class="el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="login-btn"
              type="primary"
              @click="login('formLabelAlign')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
axios.defaults.withCredentials = true
export default {
  name: 'login',
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        password: "",
      },
      loginRules: {
        name: [
          {
            required: true,
            message: "请输入姓名(至少3位)",
            min: 3,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码(至少3位)",
            min: 3,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "登陆中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          axios
            .post("/api/v1/admin/login", {
              name: this.formLabelAlign.name,
              password: this.formLabelAlign.password,
            })
            .then((response) => {
              loading.close();
              this.$router.push({
                path:"customersList",
              })
            })
            .catch((error) => {
              loading.close();
              var errTips = error.message;
              if (error.response.data != undefined) {
                errTips = error.response.data;
              }
              this.$notify.error({
                title: "登录错误",
                message: errTips,
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  background-color: rgb(45, 58, 75);
}
.login-box {
  min-width: 300px;
  width: 25%;
}
.login-input {
  height: 40px;
}
.login-btn {
  min-width: 300px;
  font-size: 12px;
}
</style>
