import Vue from "vue";
import VueRouter from "vue-router";
//一般加载需要的页面才是直接引入 否者来加载 对第一次加载的时间优化
import Introduction from "../views/PosIntroduction.vue";
import Login from "../views/admin/Login.vue";
import CompleteTips from "../views/CompleteTips.vue";
import ServiceAgreement from "../views/ServiceAgreement.vue";
import PrivacyAgreement from "../views/PrivacyAgreement.vue";
import InformationAgreement from "../views/InformationAgreement.vue";
import CustomersList from "../views/admin/CustomersList.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Introduction", //一般首字母大写
    component: Introduction,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/addressInfo",
    name: "AddressInfo",
    component: ()=>import ("../views/AddressInfo.vue"),//下面的类似
  },
  {
    path: "/completeTips",
    name: "CompleteTips",
    component: CompleteTips,
  },
  {
    path: "/serviceAgreement",
    name: "ServiceAgreement",
    component: ServiceAgreement,
  },
  {
    path: "/privacyAgreement",
    name: "PrivacyAgreement",
    component: PrivacyAgreement,
  },
  {
    path: "/informationAgreement",
    name: "InformationAgreement",
    component: InformationAgreement,
  },
 
  {
    path: "/customersList",
    name: "CustomersList",
    component: CustomersList,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
