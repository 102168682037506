<template>
  <div class="container">
    <el-image
      class="success-icon"
      :src="require('../assets/3_icon.jpg')"
      fit="contain"
    ></el-image>
    <div class="answer-prompt">
      请保持电话畅通并及时接听<br />
      否则可能无法如期发货
    </div>
    <div class="tips">
      感谢您的参与。<br />
      由于您是第一次参加活动，为确保快件能够准确送到您手中，我们将在24小时内安排专人与您核实快递信息，请保持电话通畅并及时接听。
    </div>
    <div style="justify-self: flex-end;margin-top: 6vh;">
      <div
        style="
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          width: 300px;
          margin: 0 auto;
          padding-top: 20px;
        "
      >
        <el-link
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001975"
          style="display: inline-block; text-decoration: none"
          ><img src="../assets/gongan.png" style="float: left" />
          <p style="float: left; margin: 0px 0px 0px 5px; color: #939393">
            川公网安备 51010602001975号
          </p></el-link
        >
      </div>
      <div
        style="
          width: 300px;
          margin: 0 auto;
          padding-bottom: 20px;
          text-align: center;
        "
      >
        <el-link href="https://beian.miit.gov.cn" target="_blank">
          <p style="margin: 0px 0px 0px 5px; color: #939393">
            蜀ICP备2023007112号-2
          </p></el-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default{
  mounted(){
    document.title="恭喜！已填写完成"
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  padding-top: 8vw;
  flex-direction: column;
  align-items: center;
}
.success-icon {
  width: 50%;
  min-width: 200px;
  min-height: 200px;
}
.answer-prompt {
  width: 80%;
  color: rgb(61, 136, 255);
  font-size: 4vw;
  margin-top: 6vw;
  margin-bottom: 6vw;
}
.tips {
  width: 80%;
  font-size: 3vw;
  padding: 6vw;
  text-align: start;
  line-height: 5vw;
  border: dashed 1px rgb(61, 136, 255);
  background-color: rgb(242, 253, 255);
}
</style>
