<template>
  <div class="container flex">
    <!-- <el-image :src="require('../assets/2top.jpg')"></el-image> -->
    <!-- <div class="time-container flex">
      <div class="time flex">
        <div>礼品仅限</div>
        <div class="highlight-txt">前10份免费申领</div>
        <div>，仅剩</div>
        <div class="time-txt">0</div>
        <div class="time-txt">0</div>
        <div class="time-colon">:</div>
        <div class="time-txt">{{ remainTime.substring(0, 1) }}</div>
        <div class="time-txt">{{ remainTime.substring(1, 2) }}</div>
        <div class="time-colon">:</div>
        <div class="time-txt">{{ remainTime.substring(3, 4) }}</div>
        <div class="time-txt">{{ remainTime.substring(4, 5) }}</div>
        <div>失效</div>
      </div>
    </div> -->
    <!-- <el-image
      fit="cover"
      :src="require('../assets/card_condition.png')"
    ></el-image> -->
    <div class="container-title">
      <div class="container-title-main">填写收件人信息</div>
      <div class="container-title-subtitle">
        仅限信用卡用户免费申领，快速送货上门
      </div>
    </div>
    <div class="container-form flex">
      <div class="border-bottom flex">
        <div class="left-text flex1">收货人</div>
        <div class="flex3">
          <el-input
            class="input"
            placeholder="姓名"
            v-model="formData.name"
          ></el-input>
        </div>
        <el-image class="addr-icon"
          :src="require('../assets/people.png')"
        ></el-image>
      </div>
      <div class="border-bottom flex">
        <div class="left-text flex1">手机号码</div>
        <div class="flex3">
          <el-input
            class="input"
            placeholder="手机号"
            v-model="formData.phone"
            type="tel"
            maxlength="11"
            minlength="11"
          ></el-input>
        </div>
        <el-image class="addr-icon"
          :src="require('../assets/phone_call.png')"
        ></el-image>
      </div>
      <div class="border-bottom flex">
        <div class="left-text flex1">选择地区</div>
        <div class="flex flex3">
          <el-cascader
            class="input"
            size="large"
            :options="options"
            v-model="selectedOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
            :clearable="true"
            placeholder="省、市、区、街道"
          >
          </el-cascader>
        </div>
        <el-image class="addr-icon"
          :src="require('../assets/local.png')"
        ></el-image>
      </div>
      <div class="border-bottom flex">
        <div class="left-text flex1">详细地址</div>
        <div class="flex3">
          <el-input
            class="input"
            placeholder="小区楼栋/乡村名称"
            v-model="formData.detailedAddress"
          ></el-input>
        </div>
        <el-image class="addr-icon"
          :src="require('../assets/home.png')"
        ></el-image>
      </div>
    </div>
    <div class="bottom-agreement flex">
      <el-checkbox v-model="agree"> </el-checkbox>
      我已阅读并同意
      <router-link to="/serviceAgreement">
        <el-link type="info">《服务协议》</el-link>
      </router-link>
      <router-link to="/privacyAgreement">
        <el-link type="info">《隐私保护指引》</el-link>
      </router-link>
      <router-link to="/informationAgreement">
        <el-link type="info">《个人信息处理知情同意书》</el-link>
      </router-link>
    </div>
    <el-button
      class="submit-btn"
      type="primary"
      @click="submit()"
      v-loading.fullscreen.lock="fullscreenLoading"
      >确认提交</el-button
    >
    <!-- <div class="bottom-tips">*请您留意客服电话，将有专人与您确认快递信息</div> -->
    <!-- <div style="justify-self: flex-end; margin-top: 22vh">
      <div
        style="
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          width: 300px;
          margin: 0 auto;
          padding-top: 20px;
        "
      >
        <el-link
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001975"
          style="display: inline-block; text-decoration: none"
          ><img src="../assets/gongan.png" style="float: left" />
          <p style="float: left; margin: 0px 0px 0px 5px; color: #939393">
            川公网安备 51010602001975号
          </p></el-link
        >
      </div>
      <div
        style="
          width: 300px;
          margin: 0 auto;
          padding-bottom: 20px;
          text-align: center;
        "
      >
        <el-link href="https://beian.miit.gov.cn" target="_blank">
          <p style="margin: 0px 0px 0px 5px; color: #939393">
            蜀ICP备2023007112号-2
          </p></el-link
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import { regionData, codeToText } from "element-china-area-data";
import axios from "axios";
export default {
  data() {
    return {
      agree: true,
      options: regionData,
      selectedOptions: [],
      fullscreenLoading: false,
      formData: {
        name: "",
        phone: "",
        address: "",
        detailedAddress: "",
      },
      remainTime: "14:59",
      countdown: null, // 添加一个 countdown 变量来存储定时器的引用
    };
  },
  mounted() {
    document.title = "填写订单地址";
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown(); // 在组件销毁前停止定时器
  },
  methods: {
    startCountdown() {
      var countdownDuration = 15 * 60 * 1000;
      var startTime = new Date().getTime();
      var endTime = startTime + countdownDuration;

      this.countdown = setInterval(() => {
        // 存储定时器的引用到 countdown 变量中
        var now = new Date().getTime();
        var timeDifference = endTime - now;
        if (timeDifference <= 1) {
          this.stopCountdown(); // 停止定时器
          console.log("倒计时结束!");
          this.startCountdown();
          return;
        }
        var minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        minutes = this.padZero(minutes);
        seconds = this.padZero(seconds);
        this.remainTime = minutes + ":" + seconds;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdown); // 清除定时器
    },
    padZero(num) {
      return num.toString().padStart(2, "0");
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += codeToText[this.selectedOptions[i]];
      }
      this.formData.address = loc;
    },
    submit() {
      if (
        this.formData.name == "" ||
        this.formData.phone == "" ||
        this.formData.address == "" ||
        this.formData.detailedAddress == ""
      ) {
        this.$message({
          type: "error",
          message: "请将必要信息填写完整！",
        });
      } else if (this.formData.phone.length != 11) {
        this.$message({
          type: "error",
          message: "请输入正确手机号!",
        });
      } else {
        this.fullscreenLoading = true;
        axios
          .post("/api/v1/customer/insert", {
            name: this.formData.name,
            phone: this.formData.phone,
            address: this.formData.address,
            detailedAddress: this.formData.detailedAddress,
          })
          .then((response) => {
            this.fullscreenLoading = false;
            if (response.status == 200) {
              this.$router.push({
                path: "completeTips",
              });
            }
          })
          .catch((error) => {
            this.fullscreenLoading = false;
            this.$notify.error({
              title: "error",
              message: error.response.data,
            });
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addr-icon{
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
}
.highlight-txt {
  color: rgb(255, 252, 0);
  font-weight: 600;
}
.time-container {
  width: 100%;
  height: 10vh;
  color: white;
  background-color: #3abbfd;
  background-image: linear-gradient(
    129deg,
    #3abbfd 0%,
    #42b2fd 11%,
    #6889fc 75%
  );
  justify-content: center;
  align-items: center;
}
.time {
  align-items: center;
  line-height: 3vh;
}
.time-txt {
  color: rgb(109, 158, 252);
  font-weight: 600;
  border-radius: 0.12rem;
  margin: 0 0.1rem 0 0.1rem;
  padding: 0 0.1rem 0 0.1rem;
  background-color: white;
}
.time-colon {
  font-weight: 600;
  margin: 0 0.1rem 0 0.1rem;
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.flex3 {
  flex: 3;
}
.border-bottom {
  border-bottom: solid 0.5px rgba(190, 190, 190, 0.721);
  align-items: center;
}
.container {
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin-top: 0px !important;
  flex-direction: column;
  background-color: white !important;
}
.container-title {
}
.container-title-main {
  margin: 0rem 0 1rem 0;
  font-size: 1.5rem;
  font-weight: 800;
}
.container-title-subtitle {
  margin: 1rem 0 1rem 0;
  font-size: 1rem;
}
.container-form {
  flex-direction: column;
  justify-content: space-around;
  min-height: 300px;
  margin: 0px 30px 0px 30px;
}
.left-text {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bottom-agreement {
  flex-flow: row wrap;
  justify-content: center;
  color: rgb(104, 99, 99);
  font-size: 14px;
}
.submit-btn {
  background-color: rgb(47, 124, 252) !important;
  border-radius: 30px !important;
  width: 60%;
  height: 60px;
  font-weight: 700 !important;
  font-size: 21px !important;
  color: aliceblue !important;
  align-self: center;
  margin: 10px 0px 10px 0px;
}
.bottom-tips {
  font-size: 12px;
  color: rgba(74, 70, 70, 0.849);
}
/deep/input::-webkit-input-placeholder {
  font-size: 15px;
  color: rgb(117, 117, 117);
}
</style>
<style>
.input .el-input__inner {
  border: 0px !important;
}
</style>
