<template>
  <div class="container">
    <div class="container-top">
      <div class="top-right">
        <el-date-picker
          v-model="date"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
        <el-input
          class="name-input"
          placeholder="姓名"
          v-model="searchName"
        ></el-input>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="queryByCondition(0)"
          >搜索</el-button
        >
        <el-button type="primary" icon="el-icon-download" @click="exportExcle()"
          >导出</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="pageInfo.list"
        tooltip-effect="dark"
        style="width: 100%; min-width: 700px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="120">
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="120">
        </el-table-column>
        <el-table-column prop="address" label="地址" width="300">
        </el-table-column>
        <el-table-column prop="detailedAddress" label="详细地址">
        </el-table-column>
        <el-table-column prop="createdDate" label="申请日期" width="180">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="pageInfo.pageNum"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      loading: false,
      searchName: "",
      searchPageNum: 1,
      searchPageSize: 10,
      pages: 0,
      pageNum: 1,
      pageSize: 10,
      pageInfo: [],
      tableData: [],
      multipleSelection: [],
      customersList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: "",
    };
  },
  created() {
    this.queryCustomersList();
  },
  methods: {
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          axios
            .post("/api/v1/customer/delete", {
              customerId: row.id,
            })
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.queryByCondition();
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败!",
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$message({
                type: "error",
                message: error.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryCustomersList() {
      axios
        .post("/api/v1/customer/customers", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((response) => {
          this.pageInfo = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$notify.error({
              title: "error",
              message: error.response.data,
            });
            this.$router.push({
              path: "login",
            });
          }
        });
    },
    queryByCondition(value) {
      var startDate = "";
      var endDate = "";
      if (this.date != undefined && this.date.length > 0) {
        startDate = this.date[0];
        endDate = this.date[1];
      }
      if (value === 0) {
        this.pageInfo.pageNum = 1;
      }
      axios
        .post("/api/v1/customer/query", {
          startDate: startDate,
          endDate: endDate,
          name: this.searchName,
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
        })
        .then((response) => {
          console.log(response);
          this.pageInfo = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$notify.error({
              title: "error",
              message: error.response.data,
            });
            this.$router.push({
              path: "login",
            });
          }
        });
    },
    exportExcle() {
      var startDate = "";
      var endDate = "";
      if (this.date != undefined && this.date.length > 0) {
        startDate = this.date[0];
        endDate = this.date[1];
      }
      var condition = {
        startDate: startDate,
        endDate: endDate,
        name: this.searchName,
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
      };
      axios({
        url: "/api/v1/customer/excel", // 替换为你的后端接口URL
        method: "POST",
        responseType: "blob", // 设置响应类型为blob
        data: condition,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "客户数据.xlsx"); // 设置下载文件的名称
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleCurrentChange(value) {
      this.pageInfo.pageNum = value; //获得当前页码
      this.queryByCondition();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 90%;
  padding-top: 5%;
}
.container-top {
  display: flex;
  min-width: 700px;
  justify-content: space-between;
}
.top-right {
  display: flex;
}
.name-input {
  width: 100px;
  padding-left: 10px;
}
</style>
